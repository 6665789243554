import { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getUser, userSignOut } from "../services/UserService";
import { User } from "../types";
import useFirebaseApp from "../hooks/useFirebase";
import LoadingScreen from "../screens/LoadingScreen";
import { useGlobal } from "./GlobalContext";
import Routes from "../constants/routes";

export interface AuthContextValue {
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
  logout: (navigate: any) => Promise<void>;
}

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  currentUser: null,
  setCurrentUser: () => {},
  logout: async () => {},
})

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const firebaseApp = useFirebaseApp();
  const { updateTheme } = useGlobal();

  const [currentUser, setCurrentUser] = useState<User| null>(null);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  useEffect(() => {
    onAuthStateChanged(getAuth(firebaseApp), async user => {
      setIsLoadingUserData(true);
      if (user) {
        try {
          const userData = await getUser(user.uid);
          if (userData.exists()) {
            const data = userData.data() as User;
            setCurrentUser(data);
            updateTheme(data.userPref.theme);
          } else {
            setCurrentUser(null);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setCurrentUser(null);
      }
      setIsLoadingUserData(false);
    }, _ => {
      setIsLoadingUserData(false);
    });
  }, [])

  const logout = async (navigate: any) => {
    navigate(Routes.Initial);
    setCurrentUser(null);
    await userSignOut();
  };

  if (isLoadingUserData) {
    return <LoadingScreen />
  }

  return (
    <AuthContext.Provider value={{
      currentUser,
      setCurrentUser,
      logout
    }}>
      {!isLoadingUserData && children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer;

export default AuthProvider