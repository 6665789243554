//import libraries
import React, { useEffect, useState } from 'react';
import { Text, Layout, useStyleSheet, useTheme, StyleService, Toggle } from '@ui-kitten/components';
import { ScrollView, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/Ionicons';
import { ScreenProps, UserPref } from '../types';
import { useGlobal } from '../contexts/GlobalContext';
import { useAuth } from '../contexts/AuthContext';
import { updateUser } from '../services/UserService';
import { Badge, Snackbar } from 'react-native-paper';

// create a component
const SettingsScreen = ({ navigation: { navigate, goBack }}: ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { updateTheme } = useGlobal();
  const { currentUser, setCurrentUser } = useAuth();
  
  const [checkedDarkMode, setCheckedDarkMode] = useState(currentUser?.userPref.theme == 'dark' || false);
  const [snack, setSnack] = useState<{ msg: String, visible: boolean }>({
    msg: '',
    visible: false
  });

  const showSnack = (msg: string) => {
    setSnack({ msg, visible: true });
  };

  const changeDarkMode = async (val: boolean) => {
    if (!currentUser) return;
    try {
      setCheckedDarkMode(val);
      const nextTheme = val ? 'dark' : 'light'
      const updatedUserPref: UserPref = { ...currentUser.userPref, theme: nextTheme };

      updateTheme(nextTheme);
      await updateUser(currentUser.uid, { userPref: updatedUserPref });
      setCurrentUser({ ...currentUser, userPref: updatedUserPref }); 
    } catch (err: any) {
      showSnack(err?.message || 'Something went wrong, please try again!');
    }
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <TouchableOpacity style={styles.backBtn} onPress={goBack}>
            <Icon name='chevron-back' size={20} style={styles.backBtnContent}/><Text style={styles.backBtnContent}> Back</Text>
          </TouchableOpacity>
          <Text category='h5' style={[styles.topic, styles.responsive]}>Account Settings</Text>
          <Text appearance='hint' style={[styles.subtopic, styles.responsive]}>All app-related and user-related settings are included here.</Text>
          <Layout style={[styles.menuItem, styles.menuItem1, styles.responsive]}>
            <Layout style={styles.menuItem}>
              <Icon color={theme['color-primary-500']} name='moon-outline' size={25} style={styles.icon} />
              <Layout style={styles.menuItem}>
                <Text>Dark mode </Text>
                <Badge style={{ backgroundColor: theme['color-warning-500']}}>Experimental</Badge>
              </Layout>
            </Layout>
            <Toggle checked={checkedDarkMode} onChange={changeDarkMode} status='basic' />
          </Layout>
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snack.visible}
        onDismiss={() => {
          setSnack({ ...snack, visible: false });
          setTimeout(() => setSnack(val => ({ ...val, status: undefined })), 200);
        }}
        style={{ backgroundColor: theme['color-danger-600'], margin: 15 }}
        action={{
          label: 'Dismiss',
          onPress: () => setSnack({ ...snack, visible: false }),
        }}
      >
        { snack.msg }
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 15
  },
  backBtn: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 20
  },
  backBtnContent: {
    color: 'color-primary-500' 
  },
  topic: {
    marginTop: 50,
    marginBottom: 5
  },
  responsive: {
    width: 738,
    maxWidth: '100%',
    alignSelf: 'center'
  },
  subtopic: {
    marginBottom: 35
  },
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  menuItem1: {
    justifyContent: 'space-between',
    width: '100%'
  },
  icon: {
    margin: 10,
    marginEnd: 20,
    marginBottom: 5
  },
});

//make this component available to the app
export default SettingsScreen;
