import React from 'react';
import { ImageProps, TextProps } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab, Layout, Text, useTheme } from '@ui-kitten/components';
import Routes, { protectedRoutes } from '../constants/routes';
import { useAuth } from '../contexts/AuthContext';
import Icon from 'react-native-vector-icons/Ionicons';

import LoginScreen from '../screens/auth/LoginScreen';
import RegisterScreen from '../screens/auth/RegisterScreen';
import ProfileScreen from '../screens/ProfileScreen';
import InitialScreen from '../screens/auth/InitialScreen';
import InfoPortalScreen from '../screens/InfoPortalScreen';
import EditProfileScreen from '../screens/EditProfileScreen';
import SettingsScreen from '../screens/SettingsScreen';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

// Main Navigation container
const NavContainer = () => {
  const { currentUser } = useAuth();

  return (
    <Stack.Navigator>
      <Stack.Group screenOptions={{ headerShown: false }}>
        {
          currentUser ? (
            <>
              <Stack.Screen name={Routes.Root} component={TabNavigator} options={{ headerShown: false }} />
              <Stack.Screen name={Routes.EditProfile} component={EditProfileScreen} />
              <Stack.Screen name={Routes.Settings} component={SettingsScreen} />
              <Stack.Screen name={Routes.Initial} component={InitialScreen} />
            </>
          ) : (
            <>
              <Stack.Screen name={Routes.Initial} component={InitialScreen} />
              <Stack.Screen name={Routes.Login} component={LoginScreen} />
              <Stack.Screen name={Routes.Register} component={RegisterScreen} />
              <Stack.Screen name={Routes.Root} component={TabNavigator} options={{ headerShown: false }} />
            </>
          )
        }
        
      </Stack.Group>
    </Stack.Navigator>
  );
};

// Bottom Navigation container and other function

const bottomTabs = [
  { name: 'INFORMATION PORTAL', routeName: Routes.InfoPortal, icon: 'layers', component: InfoPortalScreen },
  { name: 'PROFILE', routeName: Routes.Profile, icon: 'md-person', component: ProfileScreen  }
];

const renderIcon = (name: string, props: Partial<ImageProps> | undefined, index: number, currentIndex: number) => {
  const theme = useTheme();

  return (
    <Icon
      name={index == currentIndex ? name : `${name}-outline`}
      size={20}
      color={index == currentIndex ? theme['color-primary-500'] : theme['color-basic-600']}
      {...props}  />
  )
}

const renderText = (text: string, props: TextProps | undefined, index: number, currentIndex: number) => {
  const theme = useTheme();

  return (
    <Text
      appearance='hint'
      {...props}
      style={{ textAlign: 'center', fontSize: 12, color: index == currentIndex ? theme['color-primary-500'] : theme['color-basic-600'] }}
    >
      { text }
    </Text>
  )
}

const BottomTabBar = ({ navigation: { navigate }, state } : any) => {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const onSelectHandler = (index: number) => {
    if (protectedRoutes.includes(state.routeNames[index]) && !currentUser) {
      navigate(Routes.Register);
    } else {
      navigate(state.routeNames[index]);
    }
  }

  return (
    <>
      <Layout style={{ width: '100%', height: 1, backgroundColor: theme['border-basic-color-3'] }}></Layout>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={onSelectHandler}
      >
        {
          bottomTabs.map((e, idx) => (
            <BottomNavigationTab
              key={e.name}
              title={(props) => renderText(e.name, props, idx, state.index)}
              icon={(props) => renderIcon(e.icon, props, idx, state.index)}
            />
          ))
        }
      </BottomNavigation>
    </>
  );
};

const TabNavigator = () => (
  <Tab.Navigator
    initialRouteName={Routes.InfoPortal}
    backBehavior='history'
    tabBar={props => <BottomTabBar {...props} />}
    screenOptions={{ headerShown: false }}
  >   
    {
      bottomTabs.map((e) => (
        <Tab.Screen key={e.routeName} name={e.routeName} component={e.component} />
      ))
    }
  </Tab.Navigator>
);

export default NavContainer;
