//import libraries
import React, { useState } from 'react';
import { Text, Layout, useStyleSheet, useTheme, StyleService } from '@ui-kitten/components';
import { ScrollView, TouchableOpacity, Image, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Snackbar } from 'react-native-paper';
import { ScreenProps } from '../types';
import Routes from '../constants/routes';
import Icon from 'react-native-vector-icons/Ionicons';
import { useAuth } from '../contexts/AuthContext';

// create a component
const ProfileScreen = ({ navigation: { navigate } }: ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { logout, currentUser } = useAuth();

  const [snackVisible, setSnackVisible] = useState(false);

  const handleOpenUrl = async (url: string) => {
    try {
      if (url.includes('http://') || url.includes('https://')) {
        await Linking.openURL(url);
      } else {
        setSnackVisible(true);
      }
    } catch (err) {
      setSnackVisible(true);
    }
  }

  const gotoEditProfile = () => navigate(Routes.EditProfile);
  const gotoSettings = () => navigate(Routes.Settings);
  const openAboutUs = () => handleOpenUrl('https://rotaract3220.org/district3220');
  const openWebsite = () => handleOpenUrl('https://rotaract3220.org/');
  const openStatPage = () => handleOpenUrl('https://stats.rotaract3220.org/');
  const openFeedbackForm = () => handleOpenUrl('https://forms.gle/ypDsZKwqxj3pmjRH7');

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <Text style={[styles.heading, styles.menuItem2]} category='h6'>User Profile</Text>
          <Layout style={[styles.avatarContainer, styles.menuItem2]}>
            <Layout style={styles.logoContainer}>
              <Image source={{
                uri: `https://ui-avatars.com/api/?name=${currentUser?.firstName || 'Unknown'}+${currentUser?.lastName || 'User'}&background=random`
              }} style={{ width: 70, height: 70 }} />
            </Layout>
            <Layout style={styles.nameContainer}>
              <Text style={styles.name}>{ currentUser?.firstName || 'Unknown' } { currentUser?.lastName || 'User' }</Text>
              <Text appearance='hint' style={styles.email}>{ currentUser?.email || '' }</Text>
            </Layout>
          </Layout>
        </Layout>
        <Layout style={[styles.divider, styles.menuItem2]}></Layout>
        <Layout style={styles.container}>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem1, styles.menuItem2, { marginTop: 10 }]} onPress={gotoEditProfile}>
            <Layout style={styles.menuItem}>
              <Icon color={theme['color-primary-500']} name='md-person-outline' size={25} style={styles.icon} />
              <Text>Edit profile</Text>
            </Layout>
            <Icon color={theme['color-primary-500']} name='chevron-forward' size={25} style={[styles.icon, { marginEnd: 0 }]} />
          </TouchableOpacity>

          <Text style={[styles.subheading, styles.menuItem2]}>Preferences</Text>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem1, styles.menuItem2]}  onPress={gotoSettings}>
            <Layout style={styles.menuItem}>
              <Icon color={theme['color-primary-500']} name='settings-outline' size={25} style={styles.icon} />
              <Text>Account settings</Text>
            </Layout>
            <Icon color={theme['color-primary-500']} name='chevron-forward' size={25} style={[styles.icon, { marginEnd: 0 }]} />
          </TouchableOpacity>
          
          <Text style={[styles.subheading, styles.menuItem2]}>Feedback</Text>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem2]} onPress={openAboutUs}>
            <Icon color={theme['color-primary-500']} name='people-outline' size={25} style={styles.icon} />
            <Text>About us</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem2]} onPress={openWebsite}>
            <Icon color={theme['color-primary-500']} name='globe-outline' size={25} style={styles.icon} />
            <Text>Visit www.rotaract3220.org</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem2]} onPress={openStatPage}>
            <Icon color={theme['color-primary-500']} name='stats-chart-outline' size={25} style={styles.icon} />
            <Text>Visit stats.rotaract3220.org</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem2, { marginBottom: 20 }]} onPress={openFeedbackForm}>
            <Icon color={theme['color-primary-500']} name='chatbubble-ellipses-outline' size={25} style={styles.icon} />
            <Text>Provide feedback</Text>
          </TouchableOpacity>
        </Layout>
        <Layout style={[styles.divider, styles.menuItem2]}></Layout>
        <Layout style={styles.container}>
          <TouchableOpacity style={[styles.menuItem, styles.menuItem2, { marginVertical: 10 }]} onPress={() => logout(navigate)}>
            <Icon color={theme['color-danger-500']} name='exit-outline' size={25} style={styles.icon} />
            <Text status='danger'>Logout</Text>
          </TouchableOpacity>
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
        action={{
          label: 'Dismiss',
          onPress: () => setSnackVisible(false),
        }}>
        Something went wrong while opening the URL. Try again!
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flexDirection: 'column',
    marginHorizontal: 15,
    marginBottom: 10,
  },
  logoContainer: {
    width: 70,
    height: 70,
    backgroundColor: 'white',
    justifyContent: 'center',
    borderRadius: 50,
    overflow: 'hidden'
  },
  heading: {
    marginTop: 10
  },
  avatarContainer: {
    flexDirection: 'row',
    marginTop: 10
  },
  nameContainer: {
    justifyContent: 'center',
    marginStart: 10
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: 'border-basic-color-4'
  },
  name: {
    fontWeight: 'bold'
  },
  email: {
    fontSize: 14
  },
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  menuItem1: {
    marginBottom: 20,
    justifyContent: 'space-between',
  },
  menuItem2: {
    width: 738,
    maxWidth: '100%',
    alignSelf: 'center'
  },
  icon: {
    margin: 10,
    marginEnd: 20,
    marginBottom: 5
  },
  subheading: {
    fontSize: 12,
    marginBottom: 5,
    fontWeight: 'bold'
  }
});

//make this component available to the app
export default ProfileScreen;
