import PropTypes from 'prop-types';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Constants from 'expo-constants';
import { IAppConfig } from '../types';
import { getAppData } from '../services/FirebaseService';
import useFirebaseApp from '../hooks/useFirebase';
import { Alert } from 'react-native';
export interface GlobalContextValue {
  updateTheme: (theme: 'light' | 'dark') => void;
  appConfig: IAppConfig;
}

interface GlobalProviderProps {
  children?: ReactNode;
  updateTheme: (theme: 'light' | 'dark') => void;
}

const GlobalContext = createContext<GlobalContextValue>({
  updateTheme: () => {},
  appConfig: {
    latestVersion: "1.0.0",
    forceUpdate: false,
    informationPortalLinks: []
  }
});

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = (props: GlobalProviderProps) => {
  const { children, updateTheme } = props;
  const firebaseApp = useFirebaseApp();

  const [appConfig, setAppConfig] = useState<IAppConfig>({
    latestVersion: "1.0.0",
    forceUpdate: false,
    informationPortalLinks: []
  })

  const checkAppUpdate = async () => {
    try {
      const currentVersion = Constants.expoConfig?.version;
      const appConfig = (await getAppData(firebaseApp)).data() as IAppConfig;
      if (appConfig) {
        setAppConfig(appConfig);
        console.log('App config loaded');
      }

      if (appConfig.latestVersion !== currentVersion && appConfig.forceUpdate)
        Alert.alert(
          'This application need to be updated',
          `You are using an older version (${currentVersion}) of One Rotaract appliation. You must update to ${appConfig.latestVersion} in order to use this application.`,
          [],
          { cancelable: false }
        );
    } catch (e) {
      console.log('Something went wrong while app check:', e)
    }
  }

  useEffect(() => {
    checkAppUpdate();
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        updateTheme,
        appConfig
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GlobalConsumer = GlobalContext.Consumer;

export default GlobalContext;
