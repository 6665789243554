//import libraries
import React, { useEffect, useState } from 'react';
import { Text, Layout, useStyleSheet, useTheme, StyleService } from '@ui-kitten/components';
import { ScrollView, TouchableOpacity, Image, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { LinearGradient } from 'expo-linear-gradient';
import { Snackbar } from 'react-native-paper';
import { useGlobal } from '../contexts/GlobalContext';
import { IPortalLink } from '../types';

// create a component
const InfoPortalScreen = () => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { appConfig } = useGlobal();

  const [snackVisible, setSnackVisible] = useState(false);
  const [informationPortalLinks, setInformationPortalLinks] = useState<IPortalLink[]>([]);

  const handleOpenUrl = async (url: string) => {
    try {
      if (url.includes('http://') || url.includes('https://')) {
        await Linking.openURL(url);
      } else {
        setSnackVisible(true);
      }
    } catch (err) {
      setSnackVisible(true);
    }
  }

  useEffect(() => setInformationPortalLinks(appConfig.informationPortalLinks), [appConfig.informationPortalLinks]);

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <Layout style={[styles.logoContainer, styles.logoShadow]}>
            <Image source={require('../../assets/logo.png')} style={{ width: '100%', height: 100 }} resizeMode='contain' />
          </Layout>
          <Text status='primary' style={styles.heading}>Rotaract Information Portal</Text>
          {
            informationPortalLinks.map(link => (
              <TouchableOpacity key={link.name} style={[styles.button, styles.responsive]} onPress={() => handleOpenUrl(link.link)}>
                <LinearGradient style={styles.gradient} start={{ x: 0.0, y: 0.0 }} end={{ x: 1.0, y: 1.0 }} colors={[theme['color-primary-500'], theme['color-info-700']]}>
                  <Text style={styles.linkText}>{link.name}</Text>
                </LinearGradient>
              </TouchableOpacity>
            ))
          }
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snackVisible}
        onDismiss={() => setSnackVisible(false)}
        action={{
          label: 'Dismiss',
          onPress: () => setSnackVisible(false),
        }}>
        Something went wrong while opening the URL. Try again!
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 15
  },
  responsive: {
    maxWidth: '100%',
    width: 738,
    alignSelf: 'center'
  },
  button: {
    borderRadius: 25,
    width: '100%',
    alignSelf: 'center',
    marginVertical: 6
  },
  logoContainer: {
    width: 100,
    height: 100,
    backgroundColor: 'white',
    elevation: 10,
    marginTop: 10,
    justifyContent: 'center',
    borderRadius: 50,
    padding: 5
  },
  logoShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 10
  },
  heading: {
    textAlign: 'center',
    marginVertical: 20,
    fontSize: 20,
    fontWeight: '700'
  },
  gradient: {
    padding: 10,
    borderRadius: 50
  },
  linkText: {
    alignSelf: 'center',
    textAlign: 'center',
    color: 'color-basic-100',
    fontWeight: 'bold'
  }
});

//make this component available to the app
export default InfoPortalScreen;
