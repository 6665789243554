//import libraries
import React, { useEffect, useState } from 'react';
import { Text, Layout, useStyleSheet, useTheme, Input, Button, StyleService, SelectItem, Select, IndexPath, Spinner } from '@ui-kitten/components';
import { ScrollView, TouchableOpacity, Alert, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/Ionicons';
import { ScreenProps, customObject } from '../types';
import clubs from '../constants/clubs';
import { useAuth } from '../contexts/AuthContext';
import { updateUser } from '../services/UserService';
import { Snackbar } from 'react-native-paper';

// create a component
const EditProfileScreen = ({ navigation }: ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const { currentUser, setCurrentUser } = useAuth();

  const [user, setUser] = useState({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    club: currentUser?.club || '',
    nic: currentUser?.nic || '',
    email: currentUser?.email || ''
  })
  const [selectedIndex, setSelectedIndex] = useState<IndexPath | IndexPath[]>();
  const [hintNicVisible, setHintNicVisible] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState<{ msg: String, visible: boolean, status: string | undefined }>({
    msg: '',
    visible: false,
    status: undefined
  });

  useEffect(() => {
    if (!currentUser || isFieldsChanged) return;

    const userFields = Object.entries(user);
    let tempIsFieldsChanged: boolean = isFieldsChanged;
    userFields.forEach(([key, val]) => {
      if (!tempIsFieldsChanged && (currentUser as customObject)[key] !== val) {
        tempIsFieldsChanged = true;
      }
    })

    setIsFieldsChanged(tempIsFieldsChanged);
  }, [user]);

  const selectClub = (idx: IndexPath | IndexPath[]) => {
    setSelectedIndex(idx);
    setUser({ ...user, club: clubs[Array.isArray(idx) ? idx[0].row : idx.row ] })
  }

  const showSnack = (msg: string, status?: string) => {
    setSnack({ msg, visible: true, status });
    setLoading(false);
  };

  const updateUserAsync = async () => {
    if (!currentUser) return;

    setLoading(true);
    try {
      // Check whether if there is any empty values
      const values = Object.values(user);
      for (let i=0; i < values.length; i++) {
        if (!values[i]) {
          showSnack('Please fill all fields for the registration.');
          return;
        }
      }

      // check for the nic format
      if (!/^((N|OL|D)\d{7}|[89]\d{8}V|(19[89]|200)\d{9})$/.test(user.nic.toUpperCase())) {
        showSnack('Invalid NIC/Passport number format found!. Please check before submit.');
        return;
      }
      await updateUser(currentUser.uid, user);
      setCurrentUser({ ...currentUser, ...user })
      setLoading(false);
      setIsFieldsChanged(false);
      showSnack('Profile updated successfully', 'success-700');
    } catch (err: any) {
      showSnack(err?.message || 'Something went wrong, please try again!');
    }
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <TouchableOpacity style={styles.backBtn} onPress={navigation.goBack}>
            <Icon name='chevron-back' size={20} style={styles.backBtnContent}/><Text style={styles.backBtnContent}> Back</Text>
          </TouchableOpacity>
          <Text category='h5' style={[styles.topic, styles.responsive]}>Edit profile</Text>
          <Text appearance='hint' style={[styles.subtopic, styles.responsive]}>Please update your details in thr form below.</Text>
          <Layout style={[styles.nameInputContainer, styles.responsive]}>
            <Input
              status='basic'
              style={{ ...styles.input, ...styles.responsive, marginEnd: 5 }}
              value={user.firstName}
              placeholder='Firstname'
              onChangeText={val => setUser({ ...user, firstName: val })} />
            <Input
              status='basic'
              style={{ ...styles.input, ...styles.responsive, marginStart: 5 }}
              value={user.lastName}
              placeholder='Lastname'
              onChangeText={val => setUser({ ...user, lastName: val })} />
          </Layout>
          <Select
            style={{ ...styles.input, ...styles.responsive, ...styles.select }}
            placeholder='Club name'
            selectedIndex={selectedIndex}
            value={user.club}
            onSelect={index => selectClub(index)}
          >
            { clubs.map(e => <SelectItem key={e} title={e} />) }
          </Select>
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.nic}
            placeholder='NIC/Passport number'
            onChangeText={val => setUser({ ...user, nic: val })}
            caption={hintNicVisible ? () => (
               <Text appearance='hint' style={styles.hint}>{'NIC/Passport number is only used for validating legitimate users.'}</Text>
            ) : undefined}
            onFocus={() => setHintNicVisible(true)}
            onBlur={() => setHintNicVisible(false)} />
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.email}
            disabled
            placeholder='Email address'
            caption={() => (<Text style={{ fontSize: 12, marginStart: 12 }} appearance='hint'>For security purposes, email address cannot be changed for the moment.</Text>)}
             />
          <Button style={styles.button} onPress={updateUserAsync} disabled={!isFieldsChanged}>
            { loading ? () => <Spinner status='basic' size='small' /> : 'Update profile' }
          </Button>
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snack.visible}
        onDismiss={() => {
          setSnack({ ...snack, visible: false });
          setTimeout(() => setSnack(val => ({ ...val, status: undefined })), 200);
        }}
        style={{ backgroundColor: theme[`color-${ snack.status ?? 'danger-600'}`], margin: 15 }}
        action={{
          label: 'Dismiss',
          textColor: theme['color-basic-500'],
          onPress: () => setSnack({ ...snack, visible: false }),
        }}
      >
        { snack.msg }
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 15
  },
  nameInputContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 0
  },
  input: {
    flex: 1,
    marginBottom: 12,
    borderRadius: 25
  },
  responsive: {
    width: 738,
    maxWidth: '100%',
    alignSelf: 'center'
  },
  select: { 
    width: 738,
    maxWidth: '100%',
    overflow: 'hidden',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: 'border-basic-color-4',
  },
  hint: {
    fontSize: 12,
    marginStart: 15
  },
  button: {
    borderRadius: 25,
    width: '100%',
    maxWidth: 738,
    alignSelf: 'center',
    marginVertical: 5
  },
  backBtn: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 20
  },
  backBtnContent: {
    color: 'color-primary-500' 
  },
  topic: {
    marginTop: 50,
    marginBottom: 5
  },
  subtopic: {
    marginBottom: 35
  }
});

//make this component available to the app
export default EditProfileScreen;
