import { useCallback, useEffect, useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import * as eva from '@eva-design/eva';
import { Provider as PaperProvider } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import { ApplicationProvider } from '@ui-kitten/components';
import NavContainer from './src/navigation/Index';
import AuthProvider from './src/contexts/AuthContext';
import { GlobalProvider } from './src/contexts/GlobalContext';
import { default as lightTheme } from './src/theme/light-theme-1.json';
import LoadingScreen from './src/screens/LoadingScreen';
import { injectWebCss } from './src/services/UtilsService';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [appTheme, setAppTheme] = useState<'light' | 'dark'>('light'); 

  const onAppReady = useCallback(() => setAppIsReady(true), []);

  useEffect(() => injectWebCss(), []);

  const updateTheme = (theme: 'light' | 'dark') => setAppTheme(theme)

  return (
    <>
      <StatusBar style={appTheme == 'light' ? 'dark' : 'light'} translucent />
      { !appIsReady && <LoadingScreen /> }
      <ApplicationProvider {...eva} theme={{ ...eva[appTheme], ...lightTheme }} >
        <GlobalProvider updateTheme={updateTheme}>
          <AuthProvider>
            <PaperProvider>
              <NavigationContainer onReady={onAppReady}>
                <NavContainer />
              </NavigationContainer>
            </PaperProvider>
          </AuthProvider>
        </GlobalProvider>
      </ApplicationProvider>
    </>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();