const clubs = [
  "Rotaract Club of Achievers Lanka Business School",
  "Rotaract Club of Alumni of University of Moratuwa",
  "Rotaract Club of American National College",
  "Rotaract Club of APIIT",
  "Rotaract Club of Athugalpura",
  "Rotaract Club of Australian College of Business and Technology",
  "Rotaract Club of Australian College of Business and Technology - Kandy",
  "Rotaract Club of Battaramulla",
  "Rotaract Club of Centennial United",
  "Rotaract Club of Central Campus",
  "Rotaract club of CfPS Law School",
  "Rotaract Club of Chulipuram",
  "Rotaract Club of Chunnakam",
  "Rotaract Club of CINEC Campus",
  "Rotaract Club of Cinnamon Gardens",
  "Rotaract Club of College of Chemical Sciences",
  "Rotaract Club of Colombo",
  "Rotaract Club of Colombo Central",
  "Rotaract Club of Colombo East",
  "Rotaract Club of Colombo Fort",
  "Rotaract Club of Colombo Heritage",
  "Rotaract Club of Colombo Metropolitan",
  "Rotaract Club of Colombo Mid City",
  "Rotaract Club of Colombo Mid Town",
  "Rotaract Club of Colombo North",
  "Rotaract Club of Colombo Port City",
  "Rotaract Club of Colombo Reconnections",
  "Rotaract Club of Colombo Regency",
  "Rotaract Club of Colombo Regent",
  "Rotaract Club of Colombo Uptown",
  "Rotaract Club of Colombo West",
  "Rotaract Club of Excellence",
  "Rotaract club of Faculty of Engineering, University of Jaffna.",
  "Rotaract club of Faculty of Medicine, SUSL",
  "Rotaract Club of Faculty of Science, University of Colombo",
  "Rotaract Club of General Sir John Kotelawala Defence University",
  "Rotaract Club of General Sir John Kotelawala Defence University Southern Campus",
  "Rotaract Club of Hatton - Kotagala",
  "Rotaract Club of Horizon Campus",
  "Rotaract Club of ICBT",
  "Rotaract Club of Imperial College of Business Studies",
  "Rotaract Club of Informatics Institute of Technology",
  "Rotaract Club of Institute of Indigenous Medicine, University of Colombo",
  "Rotaract Club of JaEla - Kandana",
  "Rotaract Club of Jaffna",
  "Rotaract Club of Jaffna Mid Town",
  "Rotaract Club of Jaffna Penisula",
  "Rotaract Club of Kadawatha",
  "Rotaract Club of Kandy",
  "Rotaract Club of Kandy Hill Capital",
  "Rotaract Club Of Kandy Metropolitan",
  "Rotaract Club of Katugasthota Region",
  "Rotaract Club of Kelaniya",
  "Rotaract Club of Kilinochchi Town",
  "Rotaract Club of Kurunegala",
  "Rotaract club of Management and Science Institute",
  "Rotaract Club of Manipay",
  "Rotaract Club of Mannar Town",
  "Rotaract Club of Matale",
  "Rotaract Club of Moratuwa",
  "Rotaract Club of Nallur",
  "Rotaract Club of Nallur Heritage",
  "Rotaract Club of National School of Business Management",
  "Rotaract Club of Negombo",
  "Rotaract Club of NIBM",
  "Rotaract Club of Pamunugama",
  "Rotaract Club of Panadura",
  "Rotaract Club of PanColombo",
  "Rotaract Club of Peace City Hatton",
  "Rotaract Club of Pearl Island",
  "Rotaract Club of Rajarata University",
  "Rotaract Club of Rathnapura",
  "Rotaract Club of Royal Institute of Colombo",
  "Rotaract Club of Saegis Campus",
  "Rotaract Club of Senkadagala",
  "Rotaract Club of Singer Sri Lanka",
  "Rotaract Club of Sri Lanka Institute of Information Technology",
  "Rotaract Club of Sri Lanka Technological Campus",
  "Rotaract Club of Sri Lanka Telecom Training Centre",
  "Rotaract Club of Trincomalee",
  "Rotaract Club of Uduvil",
  "Rotaract Club of University Alumni",
  "Rotaract Club of University of Colombo School of Computing",
  "Rotaract Club of University of Colombo, Faculty of Arts",
  "Rotaract Club of University of Colombo, Faculty of Management and Finance",
  "Rotaract Club of University of Colombo, Faculty of Medicine",
  "Rotaract Club of University of Kelaniya",
  "Rotaract Club of University of Moratuwa",
  "Rotaract Club of University of Peradeniya",
  "Rotaract Club of University of Ruhuna",
  "Rotaract Club of University of Sri Jayewardenepura",
  "Rotaract Club Of UOC Law",
  "Rotaract Club of Uva Wellassa University - Badulla",
  "Rotaract Club of Wattala",
  "Rotaract Club of Wayamba University",
  "Rotaract Club of Wellawatte"
];

export default clubs;