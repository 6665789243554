import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { initializeAuth, getReactNativePersistence } from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Buffer as B } from 'buffer';

const prodString = 'eyJhcGlLZXkiOiJBSXphU3lBNHBsMFBPeDdjM0hUX1ZaRHRORWNUNmNHeEdjeDMwVE0iLCJhdXRoRG9tYWluIjoib25lLXJvdGFyYWN0LmZpcmViYXNlYXBwLmNvbSIsInByb2plY3RJZCI6Im9uZS1yb3RhcmFjdCIsInN0b3JhZ2VCdWNrZXQiOiJvbmUtcm90YXJhY3QuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjI2MzE2MjUwNDE0OSIsImFwcElkIjoiMToyNjMxNjI1MDQxNDk6d2ViOmVkOTk5ODU1OWYxOTE2M2EyYTEzNWMiLCJtZWFzdXJlbWVudElkIjoiRy1QNUZNMzkwMjdWIn0=';
const devString = 'eyJhcGlLZXkiOiJBSXphU3lETkR0VGVTWTNfd1JaN1RWV05QYWpPdkVYUTRWZ2dITnciLCJhdXRoRG9tYWluIjoib25lLXJvdGFyYWN0LWRldi5maXJlYmFzZWFwcC5jb20iLCJwcm9qZWN0SWQiOiJvbmUtcm90YXJhY3QtZGV2Iiwic3RvcmFnZUJ1Y2tldCI6Im9uZS1yb3RhcmFjdC1kZXYuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjEwMDAwNzkzMDEyNDkiLCJhcHBJZCI6IjE6MTAwMDA3OTMwMTI0OTp3ZWI6YzBhOTEzY2Q0M2E4MzBlYzUxNGI4MCIsIm1lYXN1cmVtZW50SWQiOiJHLU5IMkxGSE1NRVAifQ==';

const firebaseConfig = JSON.parse(B.from(process.env.NODE_ENV === 'development' ? devString : prodString, 'base64').toString());

let firebaseApp: FirebaseApp | null = null;

const useFirebaseApp = () => {
  if (!firebaseApp || !getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
    try {
      initializeAuth(firebaseApp, {
        persistence: getReactNativePersistence(AsyncStorage)
      })
    } catch (err: any) {
      console.warn(err?.message || 'Something went wrong while Auth init')
    }
  }
  
  return firebaseApp
}

export default useFirebaseApp;