//import libraries
import React, { useState } from 'react';
import { ScrollView, TouchableOpacity, TouchableWithoutFeedback, Platform } from 'react-native';
import { Layout, Text, StyleService, useStyleSheet, useTheme, Button, Input, Spinner } from '@ui-kitten/components';
import Icon from 'react-native-vector-icons/Ionicons';
import { SafeAreaView } from 'react-native-safe-area-context';
import Routes from '../../constants/routes';
import { ScreenProps } from '../../types';
import { Snackbar } from 'react-native-paper';
import { signIn } from '../../services/UserService';

// create a component
const LoginScreen = ({ navigation: { navigate, goBack }} : ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [hintVisible, setHintVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    msg: '',
    visible: false
  });

  const showSnack = (msg: string) => {
    setSnack({ msg, visible: true });
    setLoading(false);
  };

  const loginUser = async () => {
    setLoading(true);
    try {
      if (!email || !password) {
        showSnack('Email address and password is mandatory!');
        return;
      }

      await signIn(email, password);
      setLoading(false);
      navigate('Root');
    } catch (err: any) {
      if (err.message.includes('invalid-email'))
        showSnack('Invalid email address format!');
      else if (err.message.includes('wrong-password'))
        showSnack('Invalid email address or passwpord found, please try again!');
      else
        showSnack(err?.message || 'Something went wrong, please try again!');
    }
  } 

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <TouchableOpacity style={styles.backBtn} onPress={goBack}>
            <Icon name='chevron-back' size={20} style={styles.backBtnContent}/><Text style={styles.backBtnContent}> Back</Text>
          </TouchableOpacity>
          <Text category='h4' style={[styles.topic, styles.responsive]}>Welcome back! Glad to see you, Again!</Text>
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={email}
            placeholder='Email address'
            onChangeText={val => setEmail(val)} />
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={password}
            placeholder='Password'
            caption={() => (
              <Text appearance='hint' style={styles.hint}>{hintVisible ? 'Should contain atleast 8 charactes' : ''}</Text>
            )}
            accessoryRight={() => (
              <TouchableWithoutFeedback onPress={() => setSecureTextEntry(val => !val)}>
                <Icon size={20} color={theme['text-hint-color']} name={secureTextEntry ? 'eye-off' : 'eye'}
                />
              </TouchableWithoutFeedback>
            )}
            secureTextEntry={secureTextEntry}
            onChangeText={val => setPassword(val)}
            onFocus={() => setHintVisible(true)}
            onBlur={() => setHintVisible(false)} />
          <Button disabled={loading} style={[styles.button, styles.responsive, { backgroundColor: theme[`color-primary-${loading ? 400 : 500}`]}]} onPress={loginUser}>
            { loading ? () => <Spinner status='basic' size='small' /> : 'Login' }
          </Button>
          <Layout style={styles.linkWrapper}>
            <Text style={styles.text}>Don't have an account? </Text>
            <TouchableOpacity style={styles.linkContainer} onPress={() => navigate(Routes.Register)}>
              <Text style={styles.linkText}>Register now</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snack.visible}
        onDismiss={() => setSnack({ ...snack, visible: false })}
        style={{ backgroundColor: theme['color-danger-600'], margin: 15 }}
        action={{
          label: 'Dismiss',
          onPress: () => setSnack({ ...snack, visible: false }),
        }}
      >
        { snack.msg }
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 15
  },
  input: {
    flex: 1,
    marginBottom: Platform.OS === 'web' ? 24 : 12,
    borderRadius: 25
  },
  responsive: {
    width: 738,
    maxWidth: '100%',
    alignSelf: 'center'
  },
  hint: {
    fontSize: 12,
    marginStart: 15
  },
  button: {
    borderRadius: 25,
    marginVertical: 5
  },
  linkContainer: { 
    alignSelf: 'center',
  },
  linkWrapper: {
    marginTop: 30,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center'
  },
  linkText: {
    color: 'color-primary-500',
    fontWeight: 'bold',
    fontSize: 14,
  },
  backBtn: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 20
  },
  backBtnContent: {
    color: 'color-primary-500' 
  },
  topic: {
    marginTop: 50,
    marginBottom: 40
  },
  text: {
    fontSize: 14
  }
});

//make this component available to the app
export default LoginScreen;
