enum Routes {
    Initial = 'Initial',
    Login = 'Login',
    Register = 'Register',
    Profile = 'Profile',
    InfoPortal = 'InfoPortal',
    Root = 'Root',
    EditProfile = 'EditProfile',
    Settings = 'Settings'
}

export const protectedRoutes: string[] = [
    Routes.Profile,
    Routes.EditProfile,
    Routes.Settings
];

export default Routes;