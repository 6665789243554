//import libraries
import React, { useState } from 'react';
import { Text, Layout, useStyleSheet, useTheme, Input, Button, StyleService, SelectItem, Select, IndexPath, Spinner } from '@ui-kitten/components';
import { ScrollView, TouchableOpacity, TouchableWithoutFeedback, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/Ionicons';
import { ScreenProps } from '../../types';
import Routes from '../../constants/routes';
import clubs from '../../constants/clubs';
import { Snackbar } from 'react-native-paper';
import { addUser, getUserFromQuery, registerUser } from '../../services/UserService';
import { initialUserPref } from '../../constants/data';

// create a component
const RegisterScreen = ({ navigation: { navigate, goBack }}: ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    club: '',
    nic: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [selectedIndex, setSelectedIndex] = useState<IndexPath | IndexPath[]>();
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [secureTextEntryConfirm, setSecureTextEntryConfirm] = useState(true);
  const [hintVisible, setHintVisible] = useState(false);
  const [hintNicVisible, setHintNicVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    msg: '',
    visible: false
  });

  const selectClub = (idx: IndexPath | IndexPath[]) => {
    setSelectedIndex(idx);
    setUser({ ...user, club: clubs[Array.isArray(idx) ? idx[0].row : idx.row ] })
  }

  const showSnack = (msg: string) => {
    setSnack({ msg, visible: true });
    setLoading(false);
  };

  const registerUserAsync = async () => {
    setLoading(true);
    try {
      // Check whether if there is any empty values
      const values = Object.values(user);
      for (let i=0; i < values.length; i++) {
        if (!values[i]) {
          showSnack('Please fill all fields for the registration.');
          return;
        }
      }

      // check for the nic format
      if (!/(N|OL|D)\d{7}|[89]\d{8}V|(19[89]|200)\d{9}/.test(user.nic.toUpperCase())) {
        showSnack('Invalid NIC/Passport number format found!. Please check before submit.');
        return;
      }

      const { password, confirmPassword, ...userData } = user;

      // check whether password and confirm passwords are same
      if (password.length < 8) {
        showSnack('Password should contain atleast 8 characters');
        return;
      }

      if (password !== confirmPassword) {
        showSnack('Confirm password does not match, please try again!');
        return;
      }

      // check whether are there any users using same 
      const usersWithSameEmail = await getUserFromQuery('email', '==', user.email);
      if (usersWithSameEmail.docs.length) {
        showSnack('Email address is already in use. Please check email address before retry.');
        return;
      }

      const userCredentials = await registerUser(user.email, password);
      await addUser({
        uid: userCredentials.user.uid,
        ...userData,
        nic: userData.nic.toUpperCase(),
        userPref: initialUserPref
      });
      setLoading(false);
      navigate('Root');
    } catch (err: any) {
      showSnack(err?.message || 'Something went wrong, please try again!');
    }
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
          <TouchableOpacity style={styles.backBtn} onPress={goBack}>
            <Icon name='chevron-back' size={20} style={styles.backBtnContent}/><Text style={styles.backBtnContent}> Back</Text>
          </TouchableOpacity>
          <Text category='h4' style={[styles.topic, styles.responsive]}>Create new One-Rotaract account</Text>
          <Text appearance='hint' style={[styles.subtopic, styles.responsive]}>Please enter your details to get started</Text>
          <Layout style={[styles.nameInputContainer, styles.responsive]}>
            <Input
              status='basic'
              style={{ ...styles.input, ...styles.responsive, marginEnd: 5 }}
              value={user.firstName}
              placeholder='Firstname'
              onChangeText={val => setUser({ ...user, firstName: val })} />
            <Input
              status='basic'
              style={{ ...styles.input, ...styles.responsive, marginStart: 5 }}
              value={user.lastName}
              placeholder='Lastname'
              onChangeText={val => setUser({ ...user, lastName: val })} />
          </Layout>
          <Select
            style={{ ...styles.input, ...styles.responsive, ...styles.select }}
            placeholder='Club name'
            selectedIndex={selectedIndex}
            value={user.club}
            onSelect={index => selectClub(index)}
          >
            { clubs.map(e => <SelectItem key={e} title={e} />) }
          </Select>
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.nic}
            placeholder='NIC/Passport number'
            onChangeText={val => setUser({ ...user, nic: val })}
            caption={hintNicVisible ? () => (
               <Text appearance='hint' style={styles.hint}>{'NIC/Passport number is only used for validating legitimate users.'}</Text>
            ) : undefined}
            onFocus={() => setHintNicVisible(true)}
            onBlur={() => setHintNicVisible(false)} />
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.email}
            placeholder='Email address'
            onChangeText={val => setUser({ ...user, email: val })}
            keyboardType='email-address' />
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.password}
            autoComplete='off'
            placeholder='Password'
            accessoryRight={() => (
              <TouchableWithoutFeedback onPress={() => setSecureTextEntry(val => !val)}>
                <Icon size={20} color={theme['text-hint-color']} name={secureTextEntry ? 'eye-off' : 'eye'}
                />
              </TouchableWithoutFeedback>
            )}
            secureTextEntry={secureTextEntry}
            onChangeText={val => setUser({ ...user, password: val })}
            onFocus={() => setHintVisible(true)}
            onBlur={() => setHintVisible(false)} />
          <Input
            status='basic'
            style={[styles.input, styles.responsive]}
            value={user.confirmPassword}
            autoComplete='off'
            placeholder='Confirm Password'
            caption={() => (
              <Text appearance='hint' style={styles.hint}>{hintVisible ? 'Should contain atleast 8 charactes' : ''}</Text>
            )}
            accessoryRight={() => (
              <TouchableWithoutFeedback onPress={() => setSecureTextEntryConfirm(val => !val)}>
                <Icon size={20} color={theme['text-hint-color']} name={secureTextEntryConfirm ? 'eye-off' : 'eye'}
                />
              </TouchableWithoutFeedback>
            )}
            secureTextEntry={secureTextEntryConfirm}
            onChangeText={val => setUser({ ...user, confirmPassword: val })}
            onFocus={() => setHintVisible(true)}
            onBlur={() => setHintVisible(false)} />
          <Button disabled={loading} style={[styles.button, styles.responsive, { backgroundColor: theme[`color-primary-${loading ? 400 : 500}`]}]} onPress={registerUserAsync}>
            { loading ? () => <Spinner status='basic' size='small' /> : 'Register' }
          </Button>
          <Layout style={styles.linkWrapper}>
            <Text style={styles.text}>Already have an account? </Text>
            <TouchableOpacity style={styles.linkContainer} onPress={() => navigate(Routes.Login)}>
              <Text style={styles.linkText}>Login here</Text>
            </TouchableOpacity>
          </Layout>
        </Layout>
      </ScrollView>
      <Snackbar
        duration={5000}
        visible={snack.visible}
        onDismiss={() => setSnack({ ...snack, visible: false })}
        style={{ backgroundColor: theme['color-danger-600'], margin: 15 }}
        action={{
          label: 'Dismiss',
          onPress: () => setSnack({ ...snack, visible: false }),
        }}
      >
        { snack.msg }
      </Snackbar>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 15
  },
  nameInputContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? 6 : 0
  },
  input: {
    flex: 1,
    marginBottom: Platform.OS === 'web' ? 18 : 12,
    borderRadius: 25,
  },
  responsive: {
    width: 738,
    maxWidth: '100%',
    alignSelf: 'center'
  },
  select: { 
    width: 738,
    maxWidth: '100%',
    overflow: 'hidden',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: 'border-basic-color-4',
  },
  hint: {
    fontSize: 12,
    marginStart: 15
  },
  button: {
    borderRadius: 25,
    marginVertical: 5
  },
  linkContainer: { 
    alignSelf: 'center',
  },
  linkWrapper: {
    marginTop: 30,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center'
  },
  linkText: {
    color: 'color-primary-500',
    fontWeight: 'bold',
    fontSize: 14,
  },
  backBtn: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 20
  },
  backBtnContent: {
    color: 'color-primary-500' 
  },
  topic: {
    marginTop: 50,
    marginBottom: 5
  },
  subtopic: {
    marginBottom: 35
  },
  text: {
    fontSize: 14
  }
});

//make this component available to the app
export default RegisterScreen;
