import React from 'react';
import { Image, SafeAreaView, ActivityIndicator, View, StyleSheet } from 'react-native';

const LoadingScreen = () => {
  return (
    <SafeAreaView style={styles.container}>
      <Image source={require('../../assets/logo.png')} style={styles.img} />
      <View style={styles.inputGroup}>
          <ActivityIndicator size='large' color='#cd1956' />
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    height: '100%'
  },
  inputGroup: {
    marginVertical: 20,
    position: 'absolute',
    bottom: 100
  },
  img: {
    width: 100,
    resizeMode: 'contain',
    height: 100
  }
})

export default LoadingScreen