import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { doc, setDoc, getDoc, getFirestore, updateDoc, getDocs, query, collection, where, WhereFilterOp } from "firebase/firestore";
import { User } from "../types";

/* Auth functions */
export const registerUser = (email: string, password: string) => createUserWithEmailAndPassword(getAuth(), email, password);

export const signIn = (email: string, password: string) => signInWithEmailAndPassword(getAuth(), email, password);

export const userSignOut = () => signOut(getAuth());

/* Firestore functions */
const collectionPath = process.env.NODE_ENV === 'development' ? 'one-rotaract-dev' : 'one-rotaract-prod';

export const getUser = (docId: string) => getDoc(doc(getFirestore(), collectionPath, 'app-data', 'users', docId));

export const getUserFromQuery = (
    key: string,
    operation: WhereFilterOp,
    value: string
) => getDocs(query(collection(getFirestore(), collectionPath, 'app-data', 'users'), where(key, operation, value)))

export const addUser = (data: User) => setDoc(doc(getFirestore(), collectionPath, 'app-data', 'users', data.uid), data);

export const updateUser = (docId: string, data: Partial<User>) => updateDoc(doc(getFirestore(), collectionPath, 'app-data', 'users', docId), data);

