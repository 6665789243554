//import libraries
import React from 'react';
import { Image, ScrollView, TouchableOpacity } from 'react-native';
import { Layout, Text, StyleService, useStyleSheet, useTheme, Button } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import { LinearGradient } from 'expo-linear-gradient';
import { ScreenProps } from '../../types';
import Routes from '../../constants/routes';

// create a component
const InitialScreen = ({ navigation: { navigate }} : ScreenProps) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView>
        <Layout style={styles.container}>
            <Layout style={styles.imageContainer}>
              <LinearGradient colors={['#ffffff00', theme['color-basic-control-transparent-100'], theme['background-basic-color-1']]} style={styles.linearGrad} />
              <Image source={require('../../../assets/images/4.jpg')} resizeMode='cover' style={{ width: '100%', height: '100%', }} />
            </Layout>
            <Layout style={styles.componentContainer}>
              <Layout style={styles.logoWrapper}>
                <Image source={require('../../../assets/images/one-rotaract.png')} style={{ maxWidth: '100%', flex: 1 }} resizeMode='contain' />
              </Layout>
              <Button style={styles.button} onPress={() => navigate(Routes.Login)}>
                Login
              </Button>
              <Button appearance='outline' style={styles.button} onPress={() => navigate(Routes.Register)}>
                Register
              </Button>
              <TouchableOpacity style={styles.linkContainer} onPress={() => navigate('Root', { screen: Routes.InfoPortal })}>
                <Text style={styles.linkText}>Continue as a Guest</Text>
              </TouchableOpacity>
            </Layout>
        </Layout>
      </ScrollView>
    </SafeAreaView>
  );
};

// define your styles
const themedStyles = StyleService.create({
  safeArea: {
    flex: 1,
    backgroundColor: 'background-basic-color-1'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  imageContainer: {
    width: '100%',
    height: 360, 
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  linearGrad: {
    height: '100%',
    width: '100%',
    flex: 1,
    position: 'absolute',
    top: 0,
    zIndex: 2
  },
  componentContainer: {
    margin: 15
  },
  logoWrapper: {
    width: '100%',
    height: 100,
    paddingHorizontal: 80,
    marginBottom: 20
  },
  button: {
    borderRadius: 25,
    width: 280,
    alignSelf: 'center',
    marginVertical: 5
  },
  linkContainer: { 
    alignSelf: 'center',
    marginTop: 30
  },
  linkText: {
    color: 'color-primary-500',
    fontWeight: 'bold',
    fontSize: 14
  }
});

//make this component available to the app
export default InitialScreen;
